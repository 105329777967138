import isEmpty from 'lodash/fp/isEmpty';
import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import text, { formatCurrency } from '../../../text';
import { IconButton } from '../../button';
import List from '../../list/List';
import MemberDiscounts from './MemberDisounts';
import useData from './useData';

export const ExpandedDetails = ({
  toggleIcon,
  toggleInvoiceSummary,
  isInvoiceExpanded,
  hasTbc,
}) => {
  const {
    quote,
    currentUser,
    isEssentialUser,
    orderTotals,
    discounts,
    memberTax,
    nonMemberTax,
    otherDiscountsTotal,
    currency,
    fullPriceWithSalesHelpIncGst,
    gstPercentage,
  } = useData();

  const removeConcatenatedDiscountAmout = (str) =>
    str.replace(/\s*\(.*?\)\s*/g, '');

  return !isEmpty(quote) && !isEmpty(currentUser) ? (
    // Collapse clickable header
    <div className='InvoiceTotalSumArea'>
      {!hasTbc && (
        <div
          className={classNames('price-estimate-header', {
            'pb-2': isInvoiceExpanded,
          })}
          onClick={toggleInvoiceSummary}
        >
          <div
            className='label text-uppercase green-1'
            style={{ fontWeight: 600, fontSize: '12px' }}
          >
            {text('priceEstimate')}
          </div>

          <div className='value'>
            <div
              className={classNames('font-weight-bold mr-1 spans-container', {
                isEssentialUser,
              })}
              style={{ fontSize: '12px' }}
            >
              (
              <span className='mr-1'>
                {text(isEssentialUser ? 'memberSaved' : 'membersSave')}:{' '}
              </span>
              <span>
                {formatCurrency(
                  (orderTotals?.price?.member_discount_total +
                    (isEssentialUser ? otherDiscountsTotal.subtotal : 0)) *
                    gstPercentage,
                  currency.name,
                  currency.scale_factor,
                  {},
                  true
                )}
              </span>
              )
            </div>

            <IconButton
              iconProps={{ isOriginal: true }}
              icon={toggleIcon}
              onClick={toggleInvoiceSummary}
              className='p-0'
            />
          </div>
        </div>
      )}

      <Collapse in={isInvoiceExpanded} timeout={50}>
        <div id='ExpandedCheckoutBoxWrapper'>
          {!isEssentialUser && (
            <>
              <MemberDiscounts hasTbc={hasTbc} />
              <hr className='divider mx-0' />
            </>
          )}

          <List isPaddingless className='is-borderless mt-2'>
            <tbody>
              <List.Item
                description={text('fullPriceWithSalesHelpInGst')}
                value={
                  hasTbc ? (
                    <span className='grey-3'>{text('toBeConfirmed')}</span>
                  ) : (
                    formatCurrency(
                      fullPriceWithSalesHelpIncGst,
                      currency.name,
                      currency.scale_factor,
                      {},
                      true
                    )
                  )
                }
                className='font-weight-bold'
              />
            </tbody>
          </List>

          {isEssentialUser ? <MemberDiscounts /> : null}

          <List isPaddingless className='is-borderless'>
            <tbody>
              {discounts.selfServiceDiscountIncGst > 0 && !isEssentialUser ? (
                <List.Item
                  description={text('inAppSelfServeDiscount', {
                    discount: 10,
                  })}
                  value={`-${formatCurrency(
                    discounts.selfServiceDiscountIncGst,
                    currency.name,
                    currency.scale_factor,
                    {},
                    true
                  )}`}
                  className='grey-3'
                />
              ) : null}

              {/* --OTHER DISCOUNTS-- */}
              {orderTotals?.discounts.map((discount, index) => (
                <List.Item
                  key={index}
                  description={`${removeConcatenatedDiscountAmout(
                    discount.display_name
                  )} (inc GST)`}
                  value={`-${formatCurrency(
                    discount.price.discount,
                    currency.name,
                    currency.scale_factor,
                    {},
                    true
                  )}`}
                  className='grey-3'
                />
              ))}
            </tbody>
          </List>

          <hr className='divider mx-0' />
          <List isPaddingless className='is-borderless mt-2 mb-2'>
            <tbody>
              <List.Item
                description={text('subtotal')}
                value={formatCurrency(
                  (isEssentialUser
                    ? orderTotals?.price?.member_subtotal
                    : orderTotals?.price?.subtotal) -
                    otherDiscountsTotal.subtotal,
                  currency.name,
                  currency.scale_factor,
                  {},
                  true
                )}
              />
              <List.Item
                description={text('gst')}
                value={formatCurrency(
                  (isEssentialUser ? memberTax : nonMemberTax) -
                    otherDiscountsTotal.gst,
                  currency.name,
                  currency.scale_factor,
                  {},
                  true
                )}
              />
            </tbody>
          </List>
        </div>
      </Collapse>
    </div>
  ) : null;
};

ExpandedDetails.propTypes = {
  toggleIcon: PropTypes.string,
  toggleInvoiceSummary: PropTypes.func,
  isInvoiceExpanded: PropTypes.bool,
  hasTbc: PropTypes.bool,
};

ExpandedDetails.defaultProps = {
  toggleIcon: 'caret-down',
  toggleInvoiceSummary: () => null,
};
