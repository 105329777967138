import { useTour } from '@reactour/tour';
import classNames from 'classnames';
import _ from 'lodash/fp';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Button from '../../components/button/Button';
import MembershipButton from '../../components/button/MembershipButton';
import MeatballDropdown from '../../components/dropdown/MeatballDropdown';
import { DELETE_PROJECT_MODAL } from '../../components/modal/DeleteProjectModal';
import { DEMO_PROJECT_ACTION_MODAL } from '../../components/modal/DemoProjectActionsModal';
import { INITIATE_TOUR_MODAL } from '../../components/modal/InitiateTourModal';
import { MERGE_PROJECT_MODAL } from '../../components/modal/MergeProjectModal';
import { ADD_NOTE_MODAL } from '../../components/modal/addNoteModal/AddNoteModal';
import { INVITE_PROJECT_USERS_MODAL } from '../../components/modal/inviteProjectUsersModal/InviteProjectUsersModal';
import { POINTERRA_ID_MODAL } from '../../components/modal/pointerraIDModal/PointerraIDModal';
import { UPDATE_PRODUCT_MODAL } from '../../components/modal/updateProductModal/UpdateProductModal';
import { UPLOAD_ASSET_MODAL } from '../../components/modal/uploadAssetModal';
import Page from '../../components/page/Page';
import { AMPLITUDE_EVENTS } from '../../constants/analytics';
import { DOWNLOAD_ALL_JOB_STATUS } from '../../constants/asset';
import { FETCH_STATUS } from '../../constants/project';
import useBreakpoint from '../../hooks/useBreakpoint';
import useEventListener from '../../hooks/useEventListener';
import { usePoll } from '../../hooks/usePoll';
import useProjectPermissions from '../../hooks/useProjectPermissions';
import {
  listProjectActivities,
  toggleStaffActivities,
} from '../../redux/actions';
import { hideModal, showModal } from '../../redux/actions/modal';
import {
  generateDownloadAllUrl,
  getDownloadAllUrl,
  getLatestProject,
  getProjectById,
  resumeDownloadAll,
  setCurrentProject,
} from '../../redux/actions/project';
import { getTourStatus } from '../../redux/actions/tour';
import { DOWNLOAD_ALL_STATUS } from '../../redux/constants';
import { profileSelectors } from '../../redux/selectors/profile';
import routes from '../../routes/constants';
import text from '../../text';
import { amplitudeTrack } from '../../utilities';
import { downloadFile } from '../../utilities/files';
import { getProjectLatestJob, isDemoProject } from '../../utilities/project';
import { LayoutContext } from '../LayoutContext';
import Titlebar from '../dashboard/components/navigation/navbar/Titlebar';
import { TOURS } from '../dashboard/components/onboarding/tour';
import ActivityFilterControls from './ActivityFilterControls';
import FileManagerFallbackOverlay from './FileManagerFallbackOverlay';
import JobTimeline from './JobTimeline';
import useUpdateBookmarkRequest from '../../hooks/useUpdateBookmarkRequest';
import { useRedirectTo } from '../../hooks/useRedirect';

const { FILE_MANAGER } = AMPLITUDE_EVENTS;

const FileManager = () => {
  const history = useHistory();
  const redirect = useRedirectTo();
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const { currentProject, getCurrentProjectStatus, downloadAll, tourSelected } =
    useSelector((state) => ({
      currentProject: state.project.currentProject,
      getCurrentProjectStatus: state.project.getCurrentProjectStatus,
      currentUser: state.profileReducer.userProfile,
      isStaffActivityListVisible: state.activity.isStaffActivityListVisible,
      downloadAll: state.project.downloadAll,
      tourSelected: state.tourReducer.tourSelected,
    }));
  const isAdmin = useSelector(profileSelectors.getIsAdmin);
  const { setStartNewProjectHighlight } = useContext(LayoutContext);
  const downloadAllState = downloadAll[projectId];
  const [zipProgress, setZipProgress] = useState(0);
  const [isNavBarSticky, setNavBarIsSticky] = useState(null);
  const fileManagerContainerRef = useRef(null);

  /* ---------- PROJECT ---------- */

  const hasCurrentProject = useMemo(
    () => !!currentProject?.id,
    [currentProject]
  );

  // fetch the project by projectId if present, latest otherwise
  useEffect(() => {
    if (projectId) {
      dispatch(
        getProjectById(projectId, {
          includeJobs: true,
          includePointCloud: true,
        })
      );
    } else {
      dispatch(
        getLatestProject({
          isAdmin: false,
          includeDemo: false,
          includeJobs: true,
          includePointCloud: true,
        })
      );
    }

    // if current project is a demo, reset the current project state so returning to the file manager
    return () => {
      if (currentProject?.id && isDemoProject(currentProject))
        dispatch(setCurrentProject(null));
    };
  }, [projectId]);

  // once fetched if projectId not present, redirect to that project's URL
  useEffect(() => {
    if (
      !projectId &&
      getCurrentProjectStatus === FETCH_STATUS.DONE &&
      currentProject?.id
    ) {
      redirect.push(`${routes.fileManager.root}/${currentProject.id}`);
    }
  }, [getCurrentProjectStatus, currentProject]);

  const isFileManagerFallback = useMemo(
    () => getCurrentProjectStatus === FETCH_STATUS.DONE && !hasCurrentProject,
    [getCurrentProjectStatus, hasCurrentProject]
  );

  useEffect(() => {
    setStartNewProjectHighlight(isFileManagerFallback);
    return () => {
      setStartNewProjectHighlight(false);
    };
  }, [isFileManagerFallback]);

  /* -------------------- */

  const latestJob = useMemo(() => {
    if (!currentProject) return null;
    const job = getProjectLatestJob(currentProject);
    return {
      ...job,
      project: currentProject,
      ..._.pick(
        ['user_project', 'jobs', 'point_clouds', 'updated_at'],
        currentProject
      ),
    };
  }, [currentProject]);
  const hasLatestJob = useMemo(() => !_.isEmpty(latestJob), [latestJob]);
  const projectPermissions = useProjectPermissions(currentProject);

  const zipProgressPct = useMemo(
    () => Math.floor(100 * zipProgress),
    [zipProgress]
  );

  const handle2DMapClick = (event) => {
    event.preventDefault();
    if (!hasLatestJob) {
      console.warn('tried 2D map click without latest job');
      return;
    }
    redirect.push(
      isAdmin
        ? `${routes.order.job(latestJob?.id)}?admin=true`
        : `${routes.order.job(latestJob?.id)}`
    );
  };

  const handle3DViewerClick = (event) => {
    event.preventDefault();
    if (!projectId) {
      console.warn('tried 3D viewer click without projectId');
      return;
    }
    redirect.push(
      isAdmin
        ? `${routes.view3DExternal.root}/${projectId}?admin=true`
        : `${routes.view3DExternal.root}/${projectId}`
    );
  };

  const { poll: pollDownload } = usePoll({
    callback: async (stopPolling) => {
      if (!projectId || !downloadAllState?.queueJobId) {
        return;
      }
      const data = await dispatch(
        getDownloadAllUrl(projectId, downloadAllState.queueJobId)
      );
      if (!data) {
        stopPolling();
        return;
      }
      const { status, url, zipProgress: newZipProgress } = data;
      switch (status) {
        case DOWNLOAD_ALL_JOB_STATUS.AVAILABLE:
          downloadFile(url);
          stopPolling();
          break;
        case DOWNLOAD_ALL_JOB_STATUS.PROCESSING:
          setZipProgress(newZipProgress);
          break;
        case DOWNLOAD_ALL_JOB_STATUS.FAILED:
          console.error('failed to generate download URL');
          stopPolling();
          break;
        default:
          console.error(`unknown status: ${status}`);
          stopPolling();
          break;
      }
    },
    dependencies: [projectId, downloadAllState?.queueJobId],
    startEnabled: false,
    delay: 5000,
  });

  const handleDownloadAll = async ({ downloadIfAvailable } = {}) => {
    const data = await dispatch(generateDownloadAllUrl(projectId));
    if (!data) {
      return;
    }
    const { status, url } = data;

    switch (status) {
      case DOWNLOAD_ALL_JOB_STATUS.AVAILABLE:
        if (downloadIfAvailable) {
          downloadFile(url);
        }
        break;
      case DOWNLOAD_ALL_JOB_STATUS.PROCESSING:
        pollDownload(true);
        break;
      case DOWNLOAD_ALL_JOB_STATUS.UNAVAILABLE:
      case DOWNLOAD_ALL_JOB_STATUS.FAILED:
        break;
      default:
        console.error('unknown status', status);
        break;
    }
  };

  const handleDownloadAllClick = async () => {
    amplitudeTrack(FILE_MANAGER.EVENT, {
      action: FILE_MANAGER.DOWNLOAD_ALL_BUTTON,
    });

    await handleDownloadAll({ downloadIfAvailable: true });
  };

  const downloadAllResumed = useRef(false);
  useEffect(() => {
    if (projectId) {
      dispatch(resumeDownloadAll(projectId));
    }
  }, [projectId]);
  useEffect(() => {
    if (downloadAllResumed.current) {
      return;
    }
    if (
      projectId &&
      downloadAllState?.isLoading &&
      downloadAllState?.queueJobId
    ) {
      pollDownload(true);
      downloadAllResumed.current = true;
    }
  }, [projectId, downloadAllState?.queueJobId]);

  // useEffect(() => {
  //   if (!isEmpty(currentUser)) {
  //     dispatch(isAdmin ? getAdminJobsList() : getJobsList());
  //   }
  // }, [currentUser]);

  /* ---------- TOURS ---------- */

  const { steps, setSteps, setCurrentStep } = useTour();

  useEffect(() => {
    if (steps.length && tourSelected) {
      dispatch(showModal(INITIATE_TOUR_MODAL));
    }
  }, [steps, tourSelected]);

  useEffect(() => {
    // need to only start the tour if a valid project
    if (getCurrentProjectStatus === FETCH_STATUS.DONE && hasCurrentProject) {
      dispatch(
        getTourStatus(TOURS.TOUR_FILE_MANAGER, setSteps, setCurrentStep)
      );
    }
    return () => {
      setSteps([]); // clean up tour steps on unmount
    };
  }, []);

  useEventListener(
    'scroll',
    (e) => {
      const { scrollTop } = e.target;
      if (!fileManagerContainerRef.current?.contains(e.target)) {
        return;
      }

      // only reset to false when scrolled back to top
      // to avoid changing header in middle of interaction when the scrollTop < 250, might confuse the user the header is gone
      if (scrollTop === 0) {
        setNavBarIsSticky(false);
      }

      if (scrollTop > 0) {
        setNavBarIsSticky(true);
      }
    },
    {},
    true
  );

  const isScreenTabletAndUp = useBreakpoint(['md', 'lg', 'xl']);

  const { isUpdatingBookmarkRequest } = useUpdateBookmarkRequest();

  return (
    <Page
      className='FileManager'
      title={text('fileManager')}
      noPadding
      showTitlebar={false}
      ref={fileManagerContainerRef}
    >
      {isUpdatingBookmarkRequest ? (
        <div className='progress-bar top-appbar-loader'>
          <div className='progress-bar-value' />
        </div>
      ) : null}
      <div
        className={classNames({
          'is-sticky elevation': isScreenTabletAndUp && isNavBarSticky,
          'sticky-top': isScreenTabletAndUp,
        })}
      >
        <Titlebar showShareLink />
        <div className='topnav d-flex flex-column' style={{ width: '100%' }}>
          <ActivityFilterControls
            id='ActivityFilterControls-secondary'
            projectId={projectId}
            currentProject={currentProject}
          />
          <div className='Controls'>
            <Col className='left'>
              <Button
                id='inviteProjectUsersButton'
                variant='outline-primary'
                icon='user'
                tooltipProps={{ text: text('inviteViewProjectUsers') }}
                onClick={() => {
                  amplitudeTrack(FILE_MANAGER.EVENT, {
                    action: FILE_MANAGER.SHARE_BUTTON,
                  });
                  return dispatch(
                    !isDemoProject(currentProject)
                      ? showModal(INVITE_PROJECT_USERS_MODAL, {
                          onHide: () => {
                            dispatch(hideModal(INVITE_PROJECT_USERS_MODAL));
                            dispatch(listProjectActivities(projectId));
                          },
                          project: currentProject,
                          job: getProjectLatestJob(currentProject),
                        })
                      : showModal(DEMO_PROJECT_ACTION_MODAL)
                  );
                }}
              >
                {text('share')}
              </Button>
              <Button
                id='addNoteButton'
                icon='speech'
                variant='outline-primary'
                tooltipProps={{ text: text('addNote') }}
                onClick={() => {
                  amplitudeTrack(FILE_MANAGER.EVENT, {
                    action: FILE_MANAGER.NOTE_BUTTON,
                  });
                  return dispatch(
                    !isDemoProject(currentProject)
                      ? showModal(ADD_NOTE_MODAL, {
                          onHide: () => dispatch(hideModal(ADD_NOTE_MODAL)),
                          job: latestJob,
                        })
                      : showModal(DEMO_PROJECT_ACTION_MODAL)
                  );
                }}
              >
                {text('note')}
              </Button>
              <Button
                id='uploadFileButton'
                variant='outline-primary'
                tooltipProps={{ text: text('uploadFiles') }}
                icon='clip'
                onClick={() => {
                  amplitudeTrack(FILE_MANAGER.EVENT, {
                    action: FILE_MANAGER.UPLOAD_BUTTON,
                  });
                  return dispatch(
                    !isDemoProject(currentProject)
                      ? showModal(UPLOAD_ASSET_MODAL, {
                          projectId,
                        })
                      : showModal(DEMO_PROJECT_ACTION_MODAL)
                  );
                }}
              >
                {text('upload')}
              </Button>
              <Button
                className={classNames(
                  'download-all-button',
                  downloadAllState?.isLoading && 'loading'
                )}
                variant='outline-primary'
                icon={
                  downloadAllState?.status === DOWNLOAD_ALL_STATUS.ZIPPING
                    ? 'zip'
                    : 'download'
                }
                onClick={() => {
                  if (!downloadAllState?.isLoading) {
                    handleDownloadAllClick(projectId);
                  }
                }}
              >
                {!downloadAllState ||
                downloadAllState.status === DOWNLOAD_ALL_STATUS.IDLE ? (
                  text('downloadAll')
                ) : downloadAllState?.status === DOWNLOAD_ALL_STATUS.ZIPPING ? (
                  <>
                    <span>
                      {text('zipping')}
                      <span className='ellipsis'>...</span>
                    </span>
                    <span className='pct'>{`${zipProgressPct}%`}</span>
                  </>
                ) : (
                  <>
                    <span>
                      {text('downloading')}
                      <span className='ellipsis'>...</span>
                    </span>
                  </>
                )}
              </Button>
              <MeatballDropdown
                tooltipProps={{ text: text('more'), placement: 'top' }}
                btnClassName='btn btn-outline-primary'
              >
                <Dropdown.Item
                  disabled={!projectPermissions.is2dMapEnabled}
                  onClick={handle2DMapClick}
                >
                  {text('map2D')}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={handle3DViewerClick}
                  disabled={!projectPermissions.is3dViewerEnabled}
                >
                  {text('viewer3D')}
                </Dropdown.Item>
                <Dropdown.Item
                  id='downloadItem'
                  onClick={handleDownloadAllClick}
                >
                  {text('downloadAll')}
                </Dropdown.Item>
                {projectPermissions.isAdministrationEnabled && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      disabled={!projectId}
                      onClick={() =>
                        dispatch(
                          showModal(POINTERRA_ID_MODAL, {
                            onHide: () => {
                              dispatch(hideModal(POINTERRA_ID_MODAL));
                              dispatch(listProjectActivities(projectId));
                            },
                            job: latestJob,
                          })
                        )
                      }
                    >
                      {text('pointerraHash')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      id='updateProductButton'
                      variant='outline-primary'
                      icon='circle'
                      tooltipProps={{ text: text('updateProductStatus') }}
                      disabled={!projectId}
                      onClick={() =>
                        dispatch(
                          showModal(UPDATE_PRODUCT_MODAL, {
                            onHide: () =>
                              dispatch(hideModal(UPDATE_PRODUCT_MODAL)),
                            job: latestJob,
                          })
                        )
                      }
                    >
                      {text('statusUpdate')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => dispatch(toggleStaffActivities())}
                    >
                      {text('showLarkiLogs')}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                )}
                {!isDemoProject(currentProject) && (
                  <>
                    <Dropdown.Item
                      onClick={() =>
                        dispatch(
                          showModal(MERGE_PROJECT_MODAL, {
                            currentJob: currentProject,
                            onSuccess: (targetProject) =>
                              redirect.push(
                                routes.fileManager.viewProject(
                                  targetProject?.id
                                )
                              ),
                          })
                        )
                      }
                    >
                      {text('mergeProjects')}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      className='red'
                      disabled={!projectPermissions.isDeleteEnabled}
                      onClick={() =>
                        dispatch(
                          showModal(DELETE_PROJECT_MODAL, {
                            projectId,
                            name: currentProject.name,
                            onSuccess: () => {
                              dispatch(setCurrentProject(null));
                              redirect.push(routes.dashboard);
                            },
                          })
                        )
                      }
                    >
                      {text('deleteProject')}
                    </Dropdown.Item>
                  </>
                )}
              </MeatballDropdown>
            </Col>

            <Col className='right'>
              <Row>
                <MembershipButton variant='outline-primary' />
                <Button
                  className='add-order-button'
                  variant='primary'
                  onClick={(event) => {
                    event.preventDefault();
                    history.push({
                      pathname: routes.order.root,
                      ...(!isDemoProject(currentProject)
                        ? {
                            search: `?mergeProjectId=${currentProject?.projectId}`,
                          }
                        : {}),
                    });
                  }}
                  icon='plus'
                >
                  {text('addOrderToProject')}
                </Button>
              </Row>
            </Col>
          </div>
          <ActivityFilterControls
            id='ActivityFilterControls-tour'
            projectId={projectId}
          />
        </div>
      </div>

      <FileManagerFallbackOverlay show={isFileManagerFallback} />
      {hasCurrentProject && <JobTimeline />}
    </Page>
  );
};

export default FileManager;
