import { useCallback } from 'react';
import { matchPath, useHistory } from 'react-router-dom';
import routerHistory from '../routes/history';
import { getUser } from '../utilities/storage';

export function isValidHttpUrl(string) {
  // https://stackoverflow.com/a/43467144
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

export const getRedirectUrl = (to) => {
  if (isValidHttpUrl(to)) {
    const url = new URL(to);
    if (
      matchPath(url.pathname, {
        path: '/viewer/:projectId',
      })
    ) {
      const user = getUser();
      if (!user) {
        throw new Error('tried to redirect to viewer without user defined');
      }
      url.searchParams.append('user', encodeURIComponent(JSON.stringify(user)));
    }
    return url;
  } else {
    console.log('to', to);
    return new URL(`${process.env.LARKI_APP_URL}${to}`);
  }
};

export const isUrlExternal = (url) => {
  return url.hostname !== window.location.hostname;
};

export const redirectTo = (to, _history = undefined) => {
  const history = _history ?? routerHistory;
  const url = getRedirectUrl(to);
  const external = isUrlExternal(url);
  if (external) {
    window.location.replace(url);
  } else {
    history.push({
      pathname: url.pathname,
      search: url.search,
    });
  }
};

export const useRedirectTo = () => {
  const history = useHistory();
  const push = useCallback(
    (to) => {
      redirectTo(to, history);
    },
    [history]
  );
  return { push };
};
